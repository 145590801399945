<template>
  <div style="height: 100%;">
    <div id="notification-statistic-header"
         :style="(isShowFilter || !$vuetify.breakpoint.xs)?'height: unset;':'height: 80px; overflow: hidden;'"
         class="d-flex flex-wrap justify-sm-center mt-3 pl-3"
         style="width: 100%; background-color: rgba(65, 149, 24, 0.2);">
      <div v-if="$vuetify.breakpoint.xs" style="position: absolute; top: 4px; right: 8px;">
        <v-icon class="white rounded-xl" style="font-size: 18px;" @click="isShowFilter=!isShowFilter">
          {{ (isShowFilter) ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </div>
      <div v-if="$vuetify.breakpoint.xs && !isShowFilter" class="py-1" style="height: 80px; width: 100%;">
        <div v-for="label in filterLabel" :key="label" class="text-center" style="width: 100%;">
          {{ label }}
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ตั้งแต่
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="fromDate" min=""/>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ถึง
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="toDate" :min="fromDate"/>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ความถี่
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="frequency" :items="frequentList" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="--เลือก--"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ประเภทโรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedFactoryType" :items="factoryTypeOptions" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          โรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedFactory" :items="factoryOptions" background-color="white" dense hide-details
                          item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                          @change="selectedMeasurement=null"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          จุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedMeasurement" :items="measurementOptions" background-color="white" dense
                          hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                          @change="selectedParameter=''"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          พารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="selectedParameter" :items="parameterOptions" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="pr-3 py-2">
        <v-btn color="primary white--text" style="height: 40px;" @click="clickSearch()">
          ค้นหา
          <v-progress-circular v-show="isFetching" class="ml-3" indeterminate size="16"></v-progress-circular>
        </v-btn>
      </div>
    </div>
    <v-container :style="`height: calc(100% - ${headerHeight}px);`" class="pa-0">
      <v-row class="ma-0" style="overflow-y: auto; height: 100%;">
        <v-col v-if="!isFactoryStaff && !isProvinceStaff" class="numberLabel">
          <div class="text-center mb-3 search--text" style="width: 100%; font-weight: bold; font-size: 24px;">
            จำนวนครั้งที่เตือนภัย
          </div>
          <div class="error white--text mb-3 pt-1 myNotificationCard elevation-3">
            <div class="cardTextHeader">
              เกินมาตรฐาน
            </div>
            <div class="cardNumber">
              {{ countSevere }}
            </div>
          </div>
          <div v-if="haveRightToManageAll" class="grey white--text pt-1 myNotificationCard elevation-3">
            <div class="cardTextHeader">
              รอตรวจสอบ / ไม่ส่ง
            </div>
            <div class="cardNumber">
              {{ countUnsend }}
            </div>
          </div>
        </v-col>
        <v-col class="tableContainer" cols="12" lg="" md="" sm="" style="overflow-y: auto;">
          <v-data-table id="statistic_dataTable" :headers="headers" :items="dataList" :items-per-page="999" fixed-header
                        hide-default-footer mobile-breakpoint="0" style="height: 100%;">
            <template v-slot:item.status="{ item }">
              {{ item.status.name }}
            </template>
            <template v-slot:item.month="{ item }">
              {{ dateToThai(item.month) }}
            </template>
            <template v-slot:item.range="{ item }">
              {{ item.range.split(' - ').map(value => dateToThai(value)).join(' - ') }}
            </template>
            <template v-slot:item.actionDate="{ item }">
              <span v-if="item.translatedDate && [7, 3, 4].includes(item.status.id)">
                <span v-if="item.actionDate" :class="(item.status.id===7) ? 'red--text' : ''">
                  {{ item.translatedDate }}
                </span>
                <span v-else>
                  -
                </span>
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="d-flex align-center justify-space-between">
                <v-select v-model="item.action"
                          :background-color="(item.status.id !== 2 && item.status.id !== 6)?'grey lighten-3':'white'"
                          :disabled="item.status.id !== 2 && item.status.id !== 6"
                          :items="[{id: 1, name: 'ส่ง'},{id: 0, name: 'ไม่ส่ง'}]"
                          :placeholder="(item.status.id !== 2 && item.status.id !== 6)?'':'--เลือก--'" dense
                          hide-details item-text="name" item-value="id" outlined></v-select>
                <v-icon v-if="item.action !== item.oldAction && [1, 0].includes(item.action) && (item.status.id === 2 || item.status.id === 6)" class="ml-2" @click="prepareStatusPayload(item, item.action)">
                  mdi-content-save
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="openDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="setNotificationResult===''" class="py-9 black--text align-center justify-center text-center"
               style="font-size: 16px;">
            {{ explainText }}
          </div>
          <div v-if="setNotificationResult==='fail'" class="py-9 warning--text align-center justify-center text-center"
               style="font-size: 16px;">
            บันทึกไม่สำเร็จ
          </div>
          <div v-if="setNotificationResult==='success'"
               class="py-9 success--text align-center justify-center text-center" style="font-size: 16px;">
            บันทึกสำเร็จ
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDialog=false">
              {{ (setNotificationResult === '') ? 'ยกเลิก' : 'ปิด' }}
            </v-btn>
            <v-btn v-if="setNotificationResult===''" :disabled="isSendingStatus" class="ml-3"
                   color="search-item white--text"
                   elevation="0" @click="confirmStatus()">
              ตกลง
              <v-progress-circular v-show="isSendingStatus" class="ml-3" indeterminate size="16"></v-progress-circular>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyDatePicker from '../myDatePicker'
import { mapState } from 'vuex'
import utils from '../../assets/js/utils'

export default {
  name: 'NotificationStatistic',
  components: { MyDatePicker },
  mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.$store.dispatch('ref/getFactoryRef')
    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('ref/measurement', {})
      .then( // this parameter is not bind from vuex
        data => {
          this.measurementOptions = [{ id: '', name: 'ทั้งหมด' }, ...data]
        }
      )
    this.$store.dispatch('ref/getParameterList')
      .then( // this parameter is not bind from vuex
        data => {
          this.parameterOptions = [{ id: '', name: 'ทั้งหมด' }, ...data]
        }
      )
    this.isFetching = true
    this.$store.dispatch('notification/getNotificationList', {
      uuid: this.uuid,
      frequency: 'daily'
    })
      .then(
        data => {
          this.isFetching = false
          this.hasNextPage = data.hasNextPage
          let dataList = data.items
          dataList = this.processList(dataList)
          this.dataList = dataList
          this.countSevere = data.countSevere
          this.countWarning = data.countWarning
          this.countUnsend = data.countUnsend
          // wait list render success then calculate green block height.
          this.reCalculateHeight()
        }
      )
      .catch((e) => {
        console.warn(e)
        this.isFetching = false
      })

    const dom = document.getElementById('statistic_dataTable')
    const target = dom.getElementsByClassName('v-data-table__wrapper')[0]
    target.addEventListener('scroll', this.onNotificationListScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      isShowFilter: true,
      fromDate: '',
      toDate: '',
      frequency: 'daily',
      selectedFactory: null,
      selectedMeasurement: null,
      selectedParameter: null,
      selectedFactoryType: null,
      option: {
        from: '',
        to: '',
        frequency: 'daily',
        factoryType: '',
        factory: '',
        measurement: '',
        parameter: ''
      },
      dataList: [],
      countSevere: 0,
      countWarning: 0,
      countUnsend: 0,
      currentPage: 1,
      explainText: '',
      toSendNotificationId: 0,
      toSendNotificationAction: -99,
      // Nope, sorry.
      isFetching: false,
      hasNextPage: true,
      openDialog: false,
      isSendingStatus: false,
      setNotificationResult: '',
      // Nope, sorry.
      prependHeader: [
        {
          text: 'ชื่อโรงงาน/บริษัท',
          sortable: false,
          align: 'center',
          value: 'name'
        },
        {
          text: 'เลขทะเบียนโรงงาน (ใหม่)',
          value: 'noNew',
          align: 'center',
          sortable: false
        },
        {
          text: 'เลขทะเบียนโรงงาน (เก่า)',
          value: 'no',
          align: 'center',
          sortable: false
        }
      ],
      mainHeader: [
        {
          text: 'โรงงาน',
          value: 'factory',
          align: 'center',
          sortable: false
        },
        {
          text: 'จุดตรวจวัด',
          value: 'measCode',
          align: 'center',
          sortable: false
        },
        {
          text: 'พารามิเตอร์',
          value: 'paramName',
          align: 'center',
          sortable: false
        },
        {
          text: 'จำนวนครั้งที่ส่ง',
          value: 'count',
          align: 'center',
          sortable: false
        }
      ],
      dailyHeader: [
        {
          text: 'วันที่',
          value: 'date',
          align: 'center',
          width: 125,
          sortable: false
        },
        {
          text: 'จุดตรวจวัด',
          value: 'measCode',
          align: 'center',
          width: 95,
          sortable: false
        },
        {
          text: 'พารามิเตอร์',
          value: 'paramName',
          align: 'center',
          sortable: false
        },
        {
          text: 'ค่า',
          value: 'paramValue',
          align: 'center',
          sortable: false
        },
        {
          text: 'หน่วย',
          value: 'paramUnit',
          align: 'center',
          sortable: false
        }
      ],
      frequentList: [
        {
          id: 'daily',
          name: 'รายวัน'
        },
        {
          id: 'weekly',
          name: 'รายสัปดาห์'
        },
        {
          id: 'monthly',
          name: 'รายเดือน'
        },
        {
          id: 'annual',
          name: 'รายปี'
        }
      ],
      measurementOptions: [{ id: '', name: 'ทั้งหมด' }],
      parameterOptions: [],
      // Nope, sorry.
      headerHeight: 48
    }
  },
  methods: {
    prepareStatusPayload (notificationObj, to) {
      this.toSendNotificationId = notificationObj.id
      this.toSendNotificationAction = to
      this.setNotificationResult = ''
      if (to === 1) {
        this.explainText = 'ระบบจะส่งข้อความแจ้งเตือนไปยังผู้ใช้ในชั่วโมงถัดไป คลิก ตกลง เพื่อยืนยัน'
      } else if (to === 0) {
        this.explainText = 'ระบบจะยกเลิกการส่งข้อความแจ้งเตือนไปยังผู้ใช้ในชั่วโมงถัดไป คลิก ตกลง เพื่อยืนยัน'
      }
      this.openDialog = true
      this.isSendingStatus = false
    },
    closeDialog () {
      this.openDialog = false
      const obj = this.dataList.find(item => item.id === this.toSendNotificationId)
      obj.action = obj.oldAction
      this.toSendNotificationId = 0
      this.setNotificationResult = ''
    },
    async confirmStatus () {
      const payload = {
        uuid: this.uuid,
        notificationId: this.toSendNotificationId,
        action: this.toSendNotificationAction
      }
      this.isSendingStatus = true
      const result = await this.$store.dispatch('notification/setNotificationStatus', payload)
      this.isSendingStatus = false
      if (!result) {
        this.setNotificationResult = 'fail'
      } else {
        this.setNotificationResult = 'success'
        const obj = this.dataList.find(item => item.id === this.toSendNotificationId)
        obj.oldAction = obj.action
      }
    },
    reCalculateHeight () {
      const dom = document.getElementById('notification-statistic-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    dateToThai (date) {
      return utils.dateToThai(date)
    },
    processList (dataList) {
      const tmp = dataList.map(item => {
        let date = ''
        let time = ''
        if (item.monitoringDate) {
          const splitted = item.monitoringDate.split(' ')
          date = splitted[0]
          date = utils.dateToThai(date)
          time = splitted[1]
          time = time.substr(0, 5) + ' น.'
        }
        if (item.action === true) {
          item.action = 1
        } else if (item.action === false) {
          item.action = 0
        }
        if (item.actionDate) {
          const date = this.dateToThai(item.actionDate.split(' ')[0]).split(' ')
          item.translatedDate = date[0] + ' ' + date[1] + ' ' + date[2].substr(2, 4)
          const time = item.actionDate.split(' ')[1].split('.')[0].split(':')
          item.translatedDate += ' ' + time[0] + ':' + time[1] + ' น.'
        }
        item.oldAction = ([1, 0].includes(item.action)) ? item.action : undefined
        return { date: date, time: time, ...item }
      })
      return tmp
    },
    async get () {
      this.isFetching = true
      this.countSevere = 0
      this.countWarning = 0
      this.countUnsend = 0
      const payload = {
        ...this.option,
        uuid: this.uuid,
        page: this.currentPage
      }
      const data = await this.$store.dispatch('notification/getNotificationList', payload)
      this.hasNextPage = data.hasNextPage
      let dataList = data.items
      dataList = this.processList(dataList)
      this.dataList = [...this.dataList, ...dataList]
      this.countSevere = data.countSevere
      this.countWarning = data.countWarning
      this.countUnsend = data.countUnsend
      this.isFetching = false
    },
    onNotificationListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetching && this.hasNextPage) {
        this.currentPage += 1
        this.get()
      }
    },
    clickSearch () {
      this.option.from = this.fromDate || ''
      this.option.to = this.toDate || ''
      this.option.frequency = this.frequency || ''
      this.option.factoryType = this.selectedFactoryType || ''
      this.option.factory = this.selectedFactory || ''
      this.option.measurement = this.selectedMeasurement || ''
      this.option.parameter = this.selectedParameter || ''
      this.currentPage = 1
      this.dataList = []
      this.get()
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'province_id', 'factory_id', 'permission']),
    ...mapState('ref', ['parameterList', 'factory', 'factoryType']),
    filterLabel () {
      const ret = []
      ret.push(`ตั้งแต่วันที่ - ${utils.dateToThai(this.option.from) || 'ไม่เลือก'}`)
      ret.push(`ถึงวันที่ - ${utils.dateToThai(this.option.to) || 'ไม่เลือก'}`)
      const f = this.frequentList.find(item => item.id === this.option.frequency)
      ret.push(`ความถี่ - ${f.name}`)
      return ret
    },
    factoryOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factory]
    },
    factoryTypeOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factoryType]
    },
    isProvinceStaff () {
      return this.province_id !== 0
    },
    isFactoryStaff () {
      return this.factory_id !== 0
    },
    annualHeader () {
      return [{ text: 'ปี', value: 'year', sortable: false, align: 'center' }, ...this.mainHeader]
    },
    monthlyHeader () {
      return [{ text: 'เดือน', value: 'month', sortable: false, align: 'center', width: 95 }, ...this.mainHeader]
    },
    weeklyHeader () {
      return [{ text: 'ช่วงวันที่', value: 'range', sortable: false, align: 'center', width: 121 }, ...this.mainHeader]
    },
    headers () {
      let ret = []
      const manageColumn = [
        {
          text: 'ดำเนินการ',
          value: 'action',
          align: 'center',
          width: 150,
          sortable: false
        },
        {
          text: 'สถานะ',
          value: 'status',
          align: 'center',
          sortable: false
        },
        {
          text: 'เวลาที่จะดำเนินการ',
          value: 'actionDate',
          align: 'center',
          width: 100,
          sortable: false
        }
      ]
      switch (this.option.frequency) {
        case 'daily':
          ret = this.dailyHeader
          if (this.haveRightToManageAll) {
            ret = [...ret, ...manageColumn]
          }
          break
        case 'weekly':
          ret = this.weeklyHeader
          break
        case 'monthly':
          ret = this.monthlyHeader
          break
        case 'annual':
          ret = this.annualHeader
          break
        default:
          ret = this.weeklyHeader
          break
      }
      return [...this.prependHeader, ...ret]
    },
    haveRightToManageAll () {
      return this.permission.includes('manage all notification')
    }
  },
  watch: {
    async selectedFactory (to, from) {
      if (to !== from && !!to) {
        const tmp = await this.$store.dispatch('ref/measurement', { id_parent: to })
        this.measurementOptions = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      } else {
        const tmp = await this.$store.dispatch('ref/measurement', {})
        this.measurementOptions = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      }
    },
    async selectedMeasurement (to, from) {
      if (to !== from && !!to) {
        const tmp = await this.$store.dispatch('ref/getParameterList', { id_parent: to })
        this.parameterOptions = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      } else {
        const tmp = await this.$store.dispatch('ref/getParameterList')
        this.parameterOptions = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
      }
    },
    isShowFilter (to, from) {
      setTimeout(this.reCalculateHeight, 100)
    },
    openDialog (to, from) {
      if (to !== from && !to) {
        this.closeDialog()
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  ::v-deep .container {
    max-width: unset;
  }
}

@media (min-width: 1264px) {
  ::v-deep .container {
    max-width: 1260px;
  }
}

@media (min-width: 600px) {
  .tableContainer {
    height: 100%;
    overflow-y: auto;
  }

  .myInputWidth {
    max-width: 170px;
  }

  .numberLabel {
    max-width: 320px;
  }
}

@media (max-width: 600px) {

  .tableContainer {
    overflow-y: auto;
  }

  .numberLabel {
    width: 100%;
  }
}

.myNotificationCard {
  width: 100%;
  height: 95px;
  border-radius: 8px;
}

.cardTextHeader {
  width: 100%;
  font-size: 20px;
  text-align: center;
}

.cardNumber {
  width: 100%;
  font-size: 36px;
  text-align: center;
}

/* <editor-fold desc="data table css"> */
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  /*height: unset;*/
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}

/* </editor-fold>*/
</style>
