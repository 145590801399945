<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="componentHeight">
      <v-tabs v-model="tab" background-color="primary" grow hide-slider>
        <v-tab class="white--text">
          สถิติการเตือนภัย
          <a class="a_forRightClick" href="?tab=1" @click.prevent></a>
        </v-tab>
        <v-tab v-if="roles.includes('administrator')" class="white--text">
          ประวัติการแจ้งเตือน
          <a class="a_forRightClick" href="?tab=0" @click.prevent></a>
        </v-tab>
      </v-tabs>
      <div style="height: calc(100% - 48px);">
        <v-tabs-items v-model="tab" class="contentItem" style="height: 100%;" touchless>
          <v-tab-item style="height: 100%;">
            <notification-statistic/>
          </v-tab-item>
          <v-tab-item v-if="roles.includes('administrator')" style="height: 100%;">
            <notification-history/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>

import NotificationHistory from '../components/notification/NotificationHistory'
import NotificationStatistic from '../components/notification/NotificationStatistic'
import { mapState } from 'vuex'

export default {
  name: 'Notification',
  components: { NotificationStatistic, NotificationHistory },
  beforeMount () {
    const tab = this.$route.query.tab
    if (tab) {
      this.tab = parseInt(tab)
    }
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapState('user', ['roles'])
  },
  watch: {
    tab (to, from) {
      if (to !== from) {
        this.$router.replace({ name: this.$route.name, query: { tab: to } })
          .catch(() => {}) // remove redundant error
      }
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
  }
}

@media (min-width: 600px) {
  .componentHeight {
    height: 100%;
  }
}

::v-deep .v-tab--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
