<template>
  <div style="height: 100%;">
    <div id="notification-hist-header"
         :style="(isShowFilter || !$vuetify.breakpoint.xs)?'height: unset;':'height: 56px; overflow: hidden;'"
         class="d-flex flex-wrap justify-sm-center mt-3 pl-3"
         style="width: 100%; background-color: rgba(65, 149, 24, 0.2);">
      <div v-if="$vuetify.breakpoint.xs" style="position: absolute; top: 4px; right: 8px;">
        <v-icon class="white rounded-xl" style="font-size: 18px;" @click="isShowFilter=!isShowFilter">
          {{ (isShowFilter) ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </div>
      <div v-if="$vuetify.breakpoint.xs && !isShowFilter" class="py-1" style="height: 56px; width: 100%;">
        <div v-for="label in filterLabel" :key="label" class="text-center" style="width: 100%;">
          {{ label }}
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ตั้งแต่
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="fromDate" min=""/>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ถึง
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="toDate" :min="fromDate"/>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ประเภทโรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedFactoryType" :items="factoryTypeOptions" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          โรงงาน
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedFactory" :items="factoryOptions" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          จุดตรวจวัด
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="selectedMeasurement" :items="measurementOptions" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          พารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-autocomplete v-model="selectedParameter" :items="forSelectParameterList" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
          ผลการส่ง
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="selectedResult" :items="[{id: '', name: 'ทั้งหมด'}, {id:0, name: 'ไม่สำเร็จ'}, {id:1, name:'สำเร็จ'}]" background-color="white" dense hide-details item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="pr-3 py-2">
        <v-btn color="primary white--text" style="height: 40px;" @click="clickSearch()">ค้นหา</v-btn>
      </div>
    </div>
    <v-container :style="`height: calc(100% - ${headerHeight}px);`" class="pt-5">
      <v-data-table id="notification_dataTable" :headers="headers" :items="dataList" :items-per-page="999" fixed-header hide-default-footer item-key="dataId" mobile-breakpoint="0" style="height: 100%;">
        <template v-slot:item.status="{ item }">
          <span :class="{'error--text': item.status.id===0, 'success--text': item.status.id===1}">
            {{ item.status.name }}
          </span>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import MyDatePicker from '../myDatePicker'
import { mapState } from 'vuex'
import utils from '../../assets/js/utils'

export default {
  name: 'NotificationHistory',
  components: { MyDatePicker },
  mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('ref/measurement', {})
      .then( // this parameter is not bind from vuex
        data => { this.measurementOptions = [{ id: '', name: 'ทั้งหมด' }, ...data] }
      )
    this.$store.dispatch('ref/getFactoryRef')
      .then( // this parameter is not bind from vuex
        data => { this.factory = [{ id: '', name: 'ทั้งหมด' }, ...data] }
      )
    this.$store.dispatch('ref/getParameterList')
      .then( // this parameter is not bind from vuex
        data => { this.parameterList = data }
      )
    this.$store.dispatch('notification/getNotificationsLogList', { uuid: this.uuid })
      .then( // this parameter is not bind from vuex
        (data) => {
          this.hasNextPage = data.hasNextPage
          const dataList = data.items
          this.dataList = this.processLogList(dataList)
        }
      )

    const dom = document.getElementById('notification_dataTable')
    const target = dom.getElementsByClassName('v-data-table__wrapper')[0]
    target.addEventListener('scroll', this.onNotificationListScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      isShowFilter: true,
      headerHeight: 48,
      fromDate: null,
      toDate: null,
      selectedFactoryType: null,
      selectedFactory: null,
      selectedMeasurement: null,
      selectedParameter: null,
      selectedResult: null,
      hasNextPage: true,
      isFetching: false,
      currentPage: 1,
      headers: [
        {
          text: 'ชื่อโรงงาน/บริษัท',
          sortable: false,
          align: 'center',
          value: 'name'
        },
        {
          text: 'เลขทะเบียนโรงงาน (ใหม่)',
          value: 'noNew',
          align: 'center',
          sortable: false
        },
        {
          text: 'เลขทะเบียนโรงงาน (เก่า)',
          value: 'no',
          align: 'center',
          sortable: false
        },
        {
          text: 'วันที่',
          value: 'date',
          align: 'center',
          sortable: false,
          width: 125
        },
        {
          text: 'เวลา',
          value: 'time',
          align: 'center',
          sortable: false,
          width: 85
        },
        {
          text: 'จุดตรวจวัด',
          value: 'measCode',
          align: 'center',
          sortable: false
        },
        {
          text: 'พารามิเตอร์',
          value: 'paramName',
          align: 'center',
          sortable: false
        },
        {
          text: 'platform',
          value: 'platform',
          align: 'center',
          sortable: false
        },
        {
          text: 'ชื่อผู้ใช้',
          value: 'userName',
          align: 'center',
          sortable: false
        },
        {
          text: 'ผลการส่ง',
          value: 'status',
          align: 'center',
          sortable: false
        }
      ],
      dataList: [],
      factory: [],
      measurementOptions: [],
      parameterList: [],
      option: {
        from: '',
        to: '',
        parameter: '',
        result: ''
      }
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('notification-hist-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    processLogList (logList) {
      const tmp = logList.map((item, index) => {
        let date = ''
        let time = ''
        if (item.sentDate) {
          const splitted = item.sentDate.split(' ')
          date = splitted[0]
          date = utils.dateToThai(date)
          time = splitted[1]
          time = time.substr(0, 5) + ' น.'
        }
        return { dataId: `${item.id}_${Math.random()}`, date: date, time: time, ...item }
      })
      return tmp
    },
    async get () {
      this.isFetching = true
      const payload = {
        ...this.option,
        uuid: this.uuid,
        page: this.currentPage
      }
      const data = await this.$store.dispatch('notification/getNotificationsLogList', payload)
      this.hasNextPage = data.hasNextPage
      let dataList = data.items
      dataList = this.processLogList(dataList)
      this.dataList = [...this.dataList, ...dataList]
      this.isFetching = false
    },
    onNotificationListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetching && this.hasNextPage) {
        this.currentPage += 1
        this.get()
      }
    },
    clickSearch () {
      this.option.from = this.fromDate || ''
      this.option.to = this.toDate || ''
      this.option.parameter = this.selectedParameter || ''
      this.option.result = this.selectedResult || ''
      this.option.factoryType = this.selectedFactoryType || ''
      this.option.factory = this.selectedFactory || ''
      this.option.measurement = this.selectedMeasurement || ''
      if (this.selectedResult === '') {
        delete this.option.result
      }
      this.currentPage = 1
      this.dataList = []
      this.get()
    }
  },
  watch: {
    isShowFilter (to, from) {
      setTimeout(this.reCalculateHeight, 100)
    },
    async selectedFactory (to, from) {
      let payload = {}
      if (to) payload = { id_parent: to }
      const tmp = await this.$store.dispatch('ref/measurement', payload)
      this.measurementOptions = [{ id: '', name: 'ทั้งหมด' }, ...tmp]
    },
    async selectedMeasurement (to, from) {
      if (to) {
        this.parameterList = await this.$store.dispatch('ref/getParameterList', { id_parent: to })
      } else {
        this.parameterList = await this.$store.dispatch('ref/getParameterList')
      }
    },
    async selectedFactoryType (to, from) {
      if (to) {
        this.factory = await this.$store.dispatch('ref/getFactoryByFactoryType', { id_parent: to })
      } else {
        this.factory = await this.$store.dispatch('ref/getFactoryRef')
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['factoryType']),
    filterLabel () {
      const ret = []
      ret.push(`ตั้งแต่วันที่ - ${utils.dateToThai(this.option.from) || 'ไม่เลือก'}`)
      ret.push(`ถึงวันที่ - ${utils.dateToThai(this.option.to) || 'ไม่เลือก'}`)
      return ret
    },
    forSelectParameterList () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.parameterList]
    },
    factoryTypeOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factoryType]
    },
    factoryOptions () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factory]
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .myInputWidth {
    max-width: 170px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  ::v-deep .container {
    max-width: 90% !important;
  }
}

/* <editor-fold desc="data table css"> */
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  /*height: unset;*/
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}

/* </editor-fold>*/
</style>
